.collapse:not(.show) {
    display: none
}

.badge-primary {
    color: #fff;
    background-color: #5d9cec
}

.wrapper .aside-container {
    position: absolute;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 116;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fff
}

.wrapper .aside-container .aside-inner {
    padding-top: 55px;
    height: 100%;
    width: 220px;
    overflow: hidden
}

.sidebar-nav a svg {
    color: inherit;
}